import * as React from 'react';

import { cn } from '../../../util';
import processText from '../../processText';
import RevealWhenVisible from '../../../components/common/RevealWhenVisible';

const styles: { [key: string]: string | undefined } = require("./Messages.module.css");

export interface MessagesProps {
    messages: any[],
    left?: {
        image: any,
        name: string,
        className: string,
    },
    right: {
        image: any,
        name: string,
        className: string,
    },
    className?: string,
    classNames: {
        bubble?: string,
        bubbleBackground?: string,
        pointerLeft?: string,
        pointerRight?: string,
    },
}

export interface MessageProps {
    message: any,
    pointer: boolean,
    left: boolean,
    right: boolean,
    classNames: {
        bubble?: string,
        bubbleBackground?: string,
        pointerLeft?: string,
        pointerRight?: string,
    }
}

export interface PointerProps {
    right?: boolean,
    className?: string,
}

const Pointer: React.SFC<PointerProps> = ({ right, className }) => {
    return (
        <div className={cn(right ? styles.pointerRight : styles.pointerLeft, className)}/>
    );
}

const Message: React.FC<MessageProps> = ({ message, pointer, left, right, classNames }) => {
    return (
        <RevealWhenVisible minVisiblePixels={80} className={cn(styles.message, left && right && styles.both, left && !right && styles.left, !left && right && styles.right)}>
            <div className={cn(styles.bubble, pointer && left && styles.pointerLeft, pointer && right && styles.pointerRight, classNames.bubble)}>
                <div className={cn(styles.bubbleBackground, classNames.bubbleBackground)}>
                    {processText(message)}
                </div>
                {pointer && left && <Pointer className={classNames.pointerLeft}/> }
                {pointer && right && <Pointer right className={classNames.pointerRight}/> }
            </div>
        </RevealWhenVisible>
    );
}

export interface PersonProps {
    image: any,
    name: string,
    className: string,
}

const Person: React.SFC<PersonProps> = ({ image, name, className }) => {
    return (
        <RevealWhenVisible minVisiblePixels={112} className={styles.person}>
            <img
                className={cn(styles.personImage, className)}
                src={image}
                alt={name}
                title={name}
            />
        </RevealWhenVisible>
    );
}

const Messages: React.SFC<MessagesProps> = ({ messages, left, right, className, classNames }) => {
    return (
        <div className={cn(styles.messages, className)}>
            <div className={cn(styles.personContainer, !left && styles.hideSmall)}>
                {!!left && <Person {...left} />}
            </div>
            <div className={styles.messagesContainer}>
                {messages.map((message, idx) => (
                    <Message message={message} pointer={idx === 0} left={!!left} right={!!right} classNames={classNames} />
                ))}
            </div>
            <div className={cn(styles.personContainer, styles.right, !right && styles.hideSmall)}>
                {!!right && <Person {...right} />}
            </div>
        </div>
    );
}

export default Messages;
