import * as React from 'react';
import { cn } from '../../util';

const styles = require("./RevealWhenVisible.module.css");

const DEFAULT_MIN_VISIBLE_PIXELS = 40;

export interface RevealWhenVisibleProps {
    className?: string,
    minVisiblePixels?: number,
}

export interface RevealWhenVisibleState {
    visible: boolean,
    minVisiblePixels: number,
}

class RevealWhenVisible extends React.Component<RevealWhenVisibleProps, RevealWhenVisibleState> {
    containerRef: any;

    constructor(props: RevealWhenVisibleProps) {
        super(props);
        this.state = {
            visible: false,
            minVisiblePixels: typeof props.minVisiblePixels === "number" && props.minVisiblePixels > 0 ? props.minVisiblePixels : DEFAULT_MIN_VISIBLE_PIXELS,
        };
        this.containerRef = React.createRef();
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll = () => {
        if (!this.state.visible) {
            if (this.elementIsVisible(this.containerRef.current)) {
                this.setState({ visible: true });
            }
        }
    }
    elementIsVisible(el: any) {
        const rect = el.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        return rect.top - windowHeight + this.state.minVisiblePixels < 0;
    }
    render() {
        const { className, children } = this.props;
        return (
            <div ref={this.containerRef} className={cn(styles.container, className, this.state.visible && styles.visible)}>
                {children}
            </div>
        );
    }
}

export default RevealWhenVisible;
