import React from "react";

import { withTranslate } from "../../components/translate";
import Row from "../../components/common/Row";
import Col from "../../components/common/Col";
import ScrollToTopOnMount from "../../components/common/ScrollToTopOnMount";
import NavBar from "../../components/common/NavBar";
import Jumbotron from '../../components/common/Jumbotron';
import SEO from "../../components/SEO";

import europe from "../../assets/images/cheeksoft_europe.png";
import about from "../../assets/images/about.jpg";
import elenaIcon from "../../assets/images/elena_icon.png";
import samuliIcon from "../../assets/images/samuli_icon.png";

import "./AboutPage.css";
import Messages from "./Messages/Messages";

import girlStyle from "./Messages/GirlMessages.module.css";
import boyStyle from "./Messages/BoyMessages.module.css";
import bothStyle from "./Messages/BothMessages.module.css";

const TeamMember = function ({ mate }) {
    return (
        <div className="teammember">
            <p className="name">{mate.name}</p>
            <p className="title">{mate.title}</p>
        </div>
    )
}

export const elena = {
    image: elenaIcon,
    name: "Elena Margaria",
    className: girlStyle.person,
};
export const samuli = {
    image: samuliIcon,
    name: "Samuli Peurasaari",
    className: boyStyle.person,
};

export const ElenaMessage = function ({ message, className }) {
    return (
        <Messages
            messages={message}
            left={elena}
            className={className}
            classNames={girlStyle}
        />
    );
}

export const SamuliMessage = function ({ message, className }) {
    return (
        <Messages
            messages={message}
            right={samuli}
            className={className}
            classNames={boyStyle}
        />
    );
}

export const BothMessage = function ({ message, className }) {
    return (
        <Messages
            messages={message}
            left={elena}
            right={samuli}
            className={className}
            classNames={bothStyle}
        />
    );
}

export const ElenaSamuliMessage = function ({ both, elena, samuli, className }) {
    if (both) {
        return <BothMessage message={both} className={className} />;
    } else if (elena) {
        return <ElenaMessage message={elena} className={className} />;
    } else if (samuli) {
        return <SamuliMessage message={samuli} className={className} />;
    }
    return null;
}

const AboutPage = withTranslate(function ({ translate }) {
    return (
        <div className="about-page">
            <SEO
                title={translate("about.head.title")}
                description={translate("about.head.description")}
                path="/about"
            />
            <ScrollToTopOnMount />
            <NavBar translate={translate} />
            <header className="container pt-3 pb-2">
                <div className="intro mt-3">
                    <h1>
                        {translate("about.tagline.title")}
                    </h1>
                    <p className="h2-sub">
                        {translate("about.tagline.subtitle")}
                    </p>
                </div>
            </header>
            <div className="container pb-3">
                <div style={{
                    maxWidth: "800px",
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                }}>
                    <img
                        src={about}
                        alt={translate("about.imgAlt")}
                        title={translate("about.imgAlt")}
                        style={{
                            maxWidth: "100%",
                        }}
                    />
                </div>
                <Row justify="center">
                    <TeamMember mate={translate("about.samuli")} />
                    <TeamMember mate={translate("about.elena")} />
                </Row>
            </div>
            {/* <JumboDivider color="#FFC107"/> */}
            <Jumbotron color="#fafafa">
                <Row justify="center">
                    <Col x12 l10 className="px-0">
                        {translate("about.messages").map((message) => (
                            <ElenaSamuliMessage {...message} />
                        ))}
                        <div className="mt-2 text-center">
                            <span className="signature" style={{ fontSize: "1.9em" }}>{translate("about.signature")}</span>
                        </div>
                    </Col>
                </Row>
            </Jumbotron>
            <Jumbotron color="#fff">
                <Row justify="center">
                    <div className="intro mt-3">
                        <h2>{translate("about.company.title")}</h2>
                        <p className="h2-sub mb-3">{translate("about.company.subtitle")}</p>
                    </div>
                </Row>
                <Row className="mb-3" justify="center">
                    <Col xs12 m6 className="px-0" justify="right">
                        <div className="company-info pt-md-4 pr-md-2">
                            <h3>CheekSoft Oy</h3>
                            <p><b>FI 2828271-6</b></p>
                            <p>Väinämöisenkatu 23 B 31 <br/> 00100 Helsinki, Finland</p>
                            <p><a href="mailto:info@cheeksoft.com">info@cheeksoft.com</a></p>
                        </div>
                    </Col>
                    <Col xs12 m6 className="company-map px-0">
                        <div className="pl-md-2">
                            <a href="https://www.google.com/maps/search/?api=1&query=Helsinki">
                                <img
                                    className="map-img"
                                    alt={translate("about.company.map")}
                                    title={translate("about.company.map")}
                                    src={europe}
                                />
                            </a>
                        </div>
                    </Col>
                </Row>
            </Jumbotron>
        </div>
    )
});

export default AboutPage;
